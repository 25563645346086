<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>
		<div class="contents  padding-bottom-20 ">
			<div class="bg-white margin-top-20 padding-lr-50 padding-bottom-20">
				<div class="padding-top-20 flex align-center justify-between">
					<p class="fs20">说点什么吧...</p>
					<el-button type="primary" size="mini" @click="submitForm">转发</el-button>
				</div>
				<div class="margin-top">
					<el-input
					  type="textarea"
					  placeholder="请输入内容"
					  v-model="transmit_desc"
					  class=" w100 "
					  rows="3"
					  maxlength="200"
					  show-word-limit
					>
					</el-input>
				</div>
				<div class="margin-top fs24 bg-gray">
					<div class="flex" v-loading.fullscreen.lock="fullscreenLoading">
						<div  v-if="dataInfo && dataInfo.images" class="images-box">
							<div  v-for="(img_item,img_index) in dataInfo.images" :key="img_index">
								<div class="grid-content bg-purple img-box" v-if="dataInfo.is_video==1 && img_index==0">
									<el-image :src="img_item+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_100,h_100,m_fast'" fit="cover" class="" :preview-src-list="dataInfo.images"></el-image>
								</div>
								<div class="grid-content bg-purple img-box"  v-if="dataInfo.is_video==0 && img_index==0">
									<el-image :src="img_item"  fit="cover" class="width100 height100" :preview-src-list="dataInfo.images"></el-image>
								</div>
							</div>
						</div>
						<div class=" margin-left ">
							<div class="flex align-center justify-between  padding-top-xs">
								<div class="flex align-center writer-info">
									<div class="head-box">
										<el-image :src="user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
										<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="user.is_v==1">
									</div>
									<div class="left-info">
										<p class="name fs16">{{user.user_nickname || '--'}}</p>
									</div>
								</div>
							</div>
							<div class="padding-top-10 fs14 text-666">
								<div class="flex align-center" v-if="atype==2">
									<div class="ftag fs12 margin-right-10" :class="dataInfo.is_free==1 ? 'free_tag' :'fee_tag' ">
										{{dataInfo.is_free==1 ? '免费' : '收费'}}
									</div>
									<p class="bold fs16 margin-tb-20 text-333">{{dataInfo.title}}</p>
								</div>
								<p class="text-2em text-cut " style="word-break: break-all;" v-html="dataInfo.body" v-if="atype==2 && (is_buy==1 || dataInfo.is_free==1) "></p>
								<p class="text-2em text-cut " style="word-break: break-all;"  v-if="atype==1">{{dataInfo.body}}</p>
							</div>
							<!-- 电子书 start -->
							<div class="book-area" v-if="atype==3">
								<div class="book-list-items padding-top-20 flex margin-bottom point" @click="toDetail(2)">
									<div class="book-cover margin-right-sm" v-if="dataInfo && dataInfo.thumb">
										<img :src="dataInfo.thumb | filterArray">
										<div class="ftag fs12" :class="dataInfo.is_free==1 ? 'free_tag' :'fee_tag' ">
											{{dataInfo.is_free==1 ? '免费' : '收费'}}
										</div>
									</div>
									<div class="right-info flex flex-direction  justify-around">
										<div class="flex align-center title-box">
											<p class="book-title text-cut">{{dataInfo.title}}</p>
											<div class="ftag fs12" :class="dataInfo.is_continued==1 ? 'free_tag' :'fee_tag' ">
												{{dataInfo.is_continued==1 ? '连载' : '完结'}}
											</div>
										</div>
										<div class="flex align-center writer-info">
											<div class="" style="flex-shrink: 0;">
												<div class="head-box" v-if="dataInfo && dataInfo.user && dataInfo.user.head_img">
													<el-image :src="dataInfo.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
													<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="dataInfo.user.is_v==1">
												</div>
												<!-- <img :src="item.user.head_img" class="block-48 radius-48 margin-right-xs"> -->
											</div>
											<div class="left-info">
												<p class="name">{{dataInfo.writer_profile}}</p>
												<div class="writer-tag flex align-center margin-top-5" v-if="dataInfo.user && dataInfo.user.tags_info && dataInfo.user.tags_info.length>0 && dataInfo.user.tags_info[0]!=''">
													<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in dataInfo.user.tags_info" :key="j">{{ele}}</p>
												</div>
											</div>
										</div>
										<div class="flex align-center">
											<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
											<p class="fs12">发布时间：{{dataInfo.create_time}}</p>
										</div>
									</div>
								</div>
							</div>
							<!-- 电子书 end -->
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editArticle',
		components: {
			bottom,
			top,
			quillEditor
		},
		data() {
			return {
				transmit_desc:'', //转发描述
				aid:'', //文章id
				atype:1,//文章类型
				is_buy:0, //是否购买
				fullscreenLoading: true,
				dialogImageUrl: '',
				fileList:[],
				dialogVisible: false,
				BaseUrl:this.imgUrl,
				type:1, //1  动态  2 文章   3电子书
				content: '', //富文本内容
				editorOption: {
					placeholder: "请输入正文"
				},
				imageUrl: '', //封面
				form: {
					title: '', //标题
					content: '', //文章内容
					is_my: true,
					imageUrl: '', //封面
					artileType: '', //文章类型
					is_first: true, //是否首发
					is_pay: true, //是否付费
				},
				user:{}, //作者信息
				dataInfo:{} ,//详情信息
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		mounted() {
			this.atype = this.$route.query.ducoment_type;
			this.aid = this.$route.query.aid;
			this.getArticleDetail()
		},
		created(){
			
		},
		methods: {
			// 获取文章详情
			getArticleDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.aid
				}, res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.user = res.data.user;
						document.title = res.data.title ? res.data.title :'动态详情'
						this.dataInfo = res.data;
						this.is_buy = res.data.is_buy;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 转发
			submitForm() {
				if(!this.aid){
					this.$message.error('作品不存在');
					return;
				};
				if(!this.transmit_desc){
					this.$message.error('请输入您的分享内容');
					return;
				};
				this.ajax('post', '/v1/61cd501224790', {
					document_aid:this.aid,
					transmit_desc: this.transmit_desc
				}, res => {
					if (res.code == 1) {
						this.$message.success('转发成功');
						this.$router.go(-1);//返回上一层
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.images-box{
		width: 100px;
		height: 100px;
	}
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}
	.el-image{
		width: 100px;
		height: 100px;
		display: block !important;
	}
	.ftag {
		width: 36px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
	}
	
	.free_tag {
		color: #31CBA4;
		background: #CAF4D7;
	}
	
	.fee_tag {
		color: #FF7F37;
		background: #FDEDEB;
	}
	.img-box{
		width: 100px;
		height: 100px;
		display: block;
	}
	.head-box{
		position: relative;
	}
	.bigv{
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
	// 电子书模块 start
	.book-area{
		.book-cover {
			width: 104px;
			height: 146px;
			background: rgba(0, 0, 0, 0);
			opacity: 1;
			border-radius: 8px 0px 0px 8px;
			position: relative;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px 0px 0px 8px;
			}
			.ftag {
				position: absolute;
				top: 0;
				right: 0;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
		.head-box{
			position: relative;
			.bigv{
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
		}
		.right-info {
			height: 146px;
			.title-box{
				.ftag {
					margin-left: 10px;
					width: 36px;
					text-align: center;
					height: 20px;
					line-height: 20px;
					border-radius: 0 0 0 10px;
					font-weight: bold;
				}
				
				.free_tag {
					color: #31CBA4;
					background: #CAF4D7;
				}
				
				.fee_tag {
					color: #FF7F37;
					background: #FDEDEB;
				}
			}
		}
		
		.book-title {
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			opacity: 1;
		}
		
		.writer-info {
			.head-img {
				width: 29px;
				height: 29px;
			}
		
			.left-info {
				.name {
					font-size: 11px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					opacity: 1;
				}
			}
		
			.writer-tag {
				p {
					width: 66px;
					height: 20px;
					text-align: center;
					opacity: 1;
					font-size: 8px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #FFFFFF;
					opacity: 1;
					&:nth-child(1){
						background: url(../../assets/image/icon/label-bg1.png) no-repeat;
						background-size: cover;
					}
					&:nth-child(2){
						background: url(../../assets/image/icon/label-bg2.png) no-repeat;
						background-size: cover;
					}
				}
			}
		}
	}
</style>
